import { useDispatch } from 'react-redux';
import { useHistoryWithReferrer } from '@utils/history';

import { IProduct, IBoxBlueprint } from '@interfaces';

import { useCartModal } from '@components/common/presentational/modal/common';
import { findBlueprintBySize } from '@v2/components/pages/shop/utils';

import { packAndTrack } from '@v2/services/analytics.service';
import { apiImageSource } from '@v2/utils/http';
import {
    updateActiveBoxLine, setActiveBox, setActiveBoxBlueprint,
    recalculateActiveBoxTotals, addNewBox, addActiveBoxLines,
    setActiveBoxProductCount, useAddActiveBoxToCart, subscribeActiveBox
} from '@app/store/rtk.cart.store';
import { useShopModals } from '@app/store/rtk.modals.selectors';
import { useEntity } from '@v2/store/rtk.entities.selectors';
import BuyingForBusiness from '@v2/components/pages/cart/modals/buying-for-business';
import { useModal } from '@v2/hooks/modals';
import { useCartBoxesCount } from '@v2/store/cart.selectors';
import { calculateBundleTotal } from '@v2/utils/cart';

export const blueprintMap = (category: string, count: number) => {
    switch (category) {
        case 'pouch': return '6-pack';
        case 'bar': return '5-pack';
        case 'bonebrothpouches': return 'variety pack';
        default: return `${count} items`;
    }
}
export const categoryMap = (category: string) => {
    switch (category) {
        case 'bars': return 'smart bars';
        case 'pouches': return 'purees';
        case 'bonebrothpouches': return 'purees';
        case 'smoothies': return 'smoothies';
        case 'pouchpacks': return 'pouch packs';
        case 'bonebrothpouchpacks': return 'pouch packs';
        case 'smoothiepacks': return 'smoothie packs';
        case 'barpacks': return 'smart bars';
        default: return 'units';
    }
}

export const categoryMapSingle = (category: string) => {
    switch (category) {
        case 'bars': return 'pack of 5 bars';
        case 'pouches': return 'puree';
        case 'bonebrothpouches': return 'puree';
        case 'smoothies': return 'smoothie';
        case 'pouchpacks': return 'pack of 6 purees';
        case 'bonebrothpouchpacks': return 'pack of 6 purees';
        case 'smoothiepacks': return 'pack of 6 smoothies';
        case 'barpacks': return 'pack of 5 bars';
        case 'collaborations': return 'CEREBELLY x The Dough Project';
        default: return 'units';
    }
}

export const nominations = (category: string) => {

    switch (category) {
        case 'barpacks':
        case 'bars':
            return `*Among competing baby food pouches with similar ingredients as of April 2024. Normalized per 24 grams.`;
        default:
            return `*Among competing baby food pouches with similar ingredients as of April 2024. Normalized per 113 grams.`;
    }
}

export const useBuyingForBusiness = () => {
    const countCart = useCartBoxesCount();
    const [showBuyingForBusinessModal] = useModal({
        windowType: 'MEDIUM',
        alignType: 'CENTER',
        heightType: 'TALL',
        scroll: false,
        content: {
            component: BuyingForBusiness,
            props: {
                box: {},
                callback: () => {},
            },
        }
    });

    const buyingMoreThanTen = countCart > 10;

    return { showBuyingForBusinessModal, buyingMoreThanTen };
}

export const useAddPackAction = () => {
    const dispatch = useDispatch();
    const addActiveBoxToCart = useAddActiveBoxToCart();

    return (packBoxIndex: number, product: IProduct, blueprints: IBoxBlueprint[], boxes: any, subscribed?: boolean, size?: number ) => {
        const typeMap: { [key: string]: string } = {
            'barpacks': 'bar',
            'bonebrothpouchpacks': 'bonebrothpouch',
            'collaborations': 'non-subscription',
            'default': 'pouch',
        };

        const blueprintType = typeMap[product.category] || typeMap['default'];
        let subscribe = true;

        const blueprint: IBoxBlueprint = Object.values(blueprints)
                                                .filter((blueprint: IBoxBlueprint) => blueprint.type === blueprintType)
                                                .shift() || null;

        if (!blueprint || Object.values(blueprint).length < 1) {
            console.error(`Blueprint for type [${blueprintType}] not found`);

            return false;
        }

        if (blueprint.type === 'non-subscription') {
            product.categoryMaxItems = 10;

            blueprint.maxItems = 10;
            blueprint.minItems = 1;
            blueprint.discount = 0;

            subscribe = false;
        }

        let count = 1;

        if (size) {
            count = size;
        } else if (packBoxIndex && boxes[packBoxIndex]) {
            dispatch(setActiveBox({ index: packBoxIndex }));
            count += boxes[packBoxIndex].count;
        } else {
            dispatch(addNewBox({ subscribe: subscribe }));
            dispatch(setActiveBoxBlueprint({
                box: {
                    blueprint: blueprint as IBoxBlueprint,
                    boxType: 'product',
                    productId: product.id,
                    title: product.title,
                    image: product.mainImage
                }
            }));
        }

        if (subscribed === false) {
            dispatch(subscribeActiveBox({ subscribe: subscribed }))
        }

        dispatch(updateActiveBoxLine({ product, count }));
        dispatch(recalculateActiveBoxTotals());
        addActiveBoxToCart();

        packAndTrack('Add Pack Clicked',
            [{ 'view': 'Product Landing Page', 'section': 'Packs' }, product,
               { id: product.sku,
                content_category: product.category,
                content_ids: [product.sku],
                content_name: product.title,
                content_type: 'product',
                product_availability: product?.status === 'instock' ? 'in stock' : 'out of stock',
                availability: product?.status === 'instock' ? 'in stock' : 'out of stock',
                title: product.title,
                link: `https://cerebelly.com/product/${product.category}/${product.slug}`,
                image_link: product.thumbnailImage ? `https://cerebelly.com/wp-content/uploads/${product.thumbnailImage}` : '',
                price: `${product.priceSubscribed} USD`,
                google_product_category: 5719,
                offers: {
                    price: product.priceSubscribed,
                    priceCurrency: 'USD',
                    availability: product?.status === 'instock' ? 'in stock' : 'out of stock',
                }
            }],
            ['*', ['title', 'category', 'quantity', 'size'], '*']);
    }
}

export function useAddVarietyPackAction() {
    const dispatch = useDispatch();
    const addActiveBoxToCart = useAddActiveBoxToCart();

    return (product: IProduct, blueprints: IBoxBlueprint[], subscribed?: boolean) => {
        const blueprint: IBoxBlueprint = Object.values(blueprints)
                                            .filter((blueprint: IBoxBlueprint) => blueprint.type === 'varietypack')
                                            .shift() || ({} as any);

        if (!blueprint || Object.values(blueprint).length < 1) {
            console.error(`Blueprint for type [varietypack] not found`);

            return false;
        }

        if (blueprint) {
            const boxBlueprint: IBoxBlueprint = Object.assign({}, blueprint, {
                minItems: 0,
                maxItems: 10 * product.size,
            });

            const products: any = [];
            product.products.forEach(p => products.push({...p, categoryMaxItems: 1000 }));

            dispatch(addNewBox({}));
            dispatch(setActiveBoxBlueprint({ box: {
                blueprint: boxBlueprint,
                boxType: 'variety_pack',
                product: product,
                productId: product.id,
                title: product.title,
                image: apiImageSource(`bundle/${product.id}/${product.hash}`),
                productBlueprint: products,
            }
            }));

            if (subscribed === false) {
                dispatch(subscribeActiveBox({ subscribe: subscribed }))
            }

            dispatch(setActiveBoxProductCount({ count: 1 }));
            dispatch(addActiveBoxLines({ bundle: product }));
            dispatch(recalculateActiveBoxTotals());
            addActiveBoxToCart();

            packAndTrack('Add Variety Packs Clicked',
                [{ 'view': 'Product Landing Page', 'section': 'Variety Packs' },
                    product,
                    { id: product.id,
                        content_category: product.category,
                        content_name: product.title,
                        content_type: 'product',
                        product_availability: product.status === 'instock' ? 'in stock' : 'out of stock',
                        availability: product.status === 'instock' ? 'in stock' : 'out of stock',
                        google_product_category: 5719,
                        link: `https://cerebelly.com/bundle/${product.subcategory === 'age_bundle' ? 'age' : 'variety'}/${product.slug}`,
                        image_link: product.hash ? apiImageSource(`bundle/${product.id}/${product.hash}/`) : product.mainImage,
                        price: `${calculateBundleTotal(product)} USD`,
                        content_ids: product.products.map(prod => prod.sku),
                        offers: {
                             price: calculateBundleTotal(product),
                             productCurrency: "USD",
                             availability: product.status === 'instock' ? 'in stock' : 'out of stock'
                        }
                      }],
                ['*', ['title', 'category', 'quantity', 'size'], '*']);
        }
    }
}

export function useAddAgeBundle() {
    const dispatch = useDispatch();
    const addActiveBoxToCart = useAddActiveBoxToCart();
    const [showCartModal] = useCartModal();
    const history = useHistoryWithReferrer();
    const isSwapping = useIsSwapping();
    const blueprints = useEntity('blueprints');

    return (product: IProduct, isVeggie?: boolean, subscribed?: boolean, callback?: (() => void)) => {
        const blueprint = findBlueprintBySize({
            list: Object.values(blueprints) as IBoxBlueprint[],
            size: product.size
        });

        if (blueprint) {
            dispatch(addNewBox({}));
            // THIS IS SIMPLY AWFUL
            dispatch(setActiveBoxBlueprint({ box: {
                blueprint,
                boxType: 'age_bundle',
                productId: product.id,
                title: product.title,
                image: apiImageSource(`${isVeggie ? 'veggie-' : ''}bundle/${product.id}/${product.hash}/`),
                isVegan: isVeggie
            }}));

            if (subscribed === false) {
                dispatch(subscribeActiveBox({ subscribe: subscribed }))
            }

            dispatch(addActiveBoxLines({ bundle: product }));
            dispatch(recalculateActiveBoxTotals());
            addActiveBoxToCart();

            if (history.location.pathname.includes('buy-again')) {
                //
            } else if (isSwapping) {
                history.pushWithReferrer('/swap/confirm');
            } else {
                showCartModal();
            }

            packAndTrack('Add Bundle Clicked',
                [{ 'view': 'Product Landing Page', 'section': 'Bundles' },
                  product,
                 { id: product.id,
                   content_category: product.category,
                   content_name: product.title,
                   content_type: 'product',
                   product_availability: product.status === 'instock' ? 'in stock' : 'out of stock',
                   availability: product.status === 'instock' ? 'in stock' : 'out of stock',
                   google_product_category: 5719,
                   link: `https://cerebelly.com/bundle/${product.subcategory === 'age_bundle' ? 'age' : 'variety'}/${product.slug}`,
                   image_link: product.hash ? apiImageSource(`bundle/${product.id}/${product.hash}/`) : product.mainImage,
                   price: `${calculateBundleTotal(product)} USD`,
                   content_ids: product.products.map(prod => prod.sku),
                   offers: {
                        price: calculateBundleTotal(product),
                        productCurrency: "USD",
                        availability: product.status === 'instock' ? 'in stock' : 'out of stock'
                   }
                 }],
                ['*', ['title', 'category', 'quantity', 'size'], '*']);
        }

        return callback ? callback() : null;
    }

}

export function useIsSwapping() {
    const history = useHistoryWithReferrer();
    const shopModals = useShopModals();

    const modalLoaded = shopModals.reduce((found, settings) => settings.path && settings.path.startsWith('/swap'), false);

    return history && history.location && history.location.pathname && history.location.pathname.startsWith('/swap') && modalLoaded
}